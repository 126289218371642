import React, { Fragment } from "react";
import "./About.css";

const About = () => {
  return (
    <Fragment>
      <div className="about-us">
        <h1>About GNR Shopping</h1>
        <p>
          Welcome to GNR Shopping, your number one source for all things
          electronics, fashion, home goods etc.... We're dedicated to providing
          you the very best of Stationary, Electronics, Gift Articles, Sports,
          Art & Craft and many more with an emphasis on quality, customer
          service, and uniqueness.
        </p>
        <p>
          We now serve customers all over India, and are thrilled that we're
          able to turn our passion into our own website.
        </p>
        <p>
          At GNR Shopping, we believe in offering an exceptional shopping
          experience. Our team is dedicated to ensuring that you receive not
          only top-quality products but also unmatched customer service. We work
          tirelessly to source the best products, negotiate the best prices, and
          deliver them to you quickly and efficiently.
        </p>

        <h2>Our Mission</h2>
        <p>
          Our mission is to provide our customers with an easy, safe, and
          enjoyable online shopping experience. We strive to bring you the
          latest trends and high-quality products at the best prices. We are
          committed to making shopping online easy and accessible for everyone.
        </p>

        <h2>Our Values</h2>
        <ul>
          <li>
            <strong>Customer Satisfaction</strong>: Our customers are our top
            priority. We aim to exceed their expectations with every purchase.
          </li>
          <li>
            <strong>Quality Assurance</strong>: We carefully select our products
            to ensure that you receive only the best.
          </li>
          <li>
            <strong>Innovation</strong>: We constantly update our product range
            to include the latest trends and innovations.
          </li>
          <li>
            <strong>Integrity</strong>: We operate with transparency and honesty
            in all our interactions.
          </li>
        </ul>

        <h2>Why Shop With Us?</h2>
        <ul>
          <li>
            <strong>Wide Range of Products</strong>: From Staionary to
            Electronics, we offer a diverse selection of products to meet all
            your needs.
          </li>
          <li>
            <strong>Secure Shopping</strong>: Your privacy and security are of
            utmost importance to us. We use the latest encryption technology to
            ensure that your personal information is safe.
          </li>
          <li>
            <strong>Fast Delivery</strong>: We work with reliable shipping
            partners to ensure your orders reach you as quickly as possible.
          </li>
          <li>
            <strong>Excellent Customer Service</strong>: Our friendly and
            knowledgeable customer service team is always here to help.
          </li>
        </ul>

        <p>
          Thank you for choosing GNR Shopping. We hope you enjoy our products as
          much as we enjoy offering them to you. If you have any questions or
          comments, please don't hesitate to contact us.
        </p>

        <p>
          <strong>Happy shopping!</strong>
        </p>

        <p>Founder & CEO, GNR Shopping</p>
      </div>
    </Fragment>
  );
};

export default About;
