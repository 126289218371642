import React from 'react';
import './ReturnPolicy.css';

const ReturnPolicy = () => {
  return (
    <div className="return-policy-container">
      <h1>Return Policy</h1>
      
      <h2>Overview</h2>
      <p>Our return policy lasts 4 days. If 4 days have gone by since your delivery, unfortunately, we can’t offer you a refund or exchange.</p>
      
      <h2>Eligibility</h2>
      <p>To be eligible for a return, your item must be unused and in the same condition that you received it. It must also be in the original packaging.</p>
      <ul>
        <li>Product must be unused and in original condition.</li>
        <li>Product must be returned in the original packaging.</li>
        <li>A receipt or proof of purchase is required.</li>
      </ul>
      <h2>How to Return an Item</h2>
      <p>To return your product, you should mail your product to:</p>
      <p>And, Main Road, Post, In Front of Govt Junior College, Kaiwara, Karnataka 563128</p>
      <p>Please email us at <a href="mailto:gnrshopping2@gmail.com">gnrshopping2@gmail.com</a> to initiate a return. In your email, include your order details (order number, item name, and reason for return) for faster processing of your return request.</p>
      
      <h2>Shipping Costs</h2>
      <p>You will be responsible for paying for your own shipping costs for returning your item. Shipping costs are non-refundable. If you receive a refund, the cost of return shipping will be deducted from your refund.</p>
      
      <h2>Exchanges</h2>
      <p>We only replace items if they are defective or damaged. If you need to exchange it for the same item, send us an email at <a href="mailto:gnrshopping2@gmail.com">gnrshopping2@gmail.com</a> and send your item to: And, Main Road, Post, In Front of Govt Junior College, Kaiwara, Karnataka 563128.</p>
      
      <h2>Contact Us</h2>
      <p>If you have any questions on how to return your item to us, or for any other queries, please contact us:</p>
      <ul>
        <li>Phone: 7676888339</li>
        <li>Email: <a href="mailto:gnrshopping2@gmail.com">gnrshopping2@gmail.com</a></li>
        <li>Address: And, Main Road, Post, In Front of Govt Junior College, Kaiwara, Karnataka 563128</li>
      </ul>
    </div>
  );
};

export default ReturnPolicy;
