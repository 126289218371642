
import React, { useEffect } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import "./OrderSuccess.css";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { removeItemsFromCart } from "../../actions/cartAction";

const OrderSuccess = () => {
  const { cartItems } = useSelector((state) => state.cart)
  const dispatch = useDispatch();

  useEffect(() => {
    cartItems.map((item) => (
    dispatch(removeItemsFromCart(item.product))
    ))
  }, [dispatch,cartItems]);

  return (
    <div className="orderSuccess">
      <CheckCircleIcon />
      <Typography>Your Order has been Placed successfully</Typography>
      <Link to="/orders">View Orders</Link>
    </div>
  );
};

export default OrderSuccess;
