import React, { Fragment } from "react";
import './ContactUs.css';

const ContactUs = () => {
    return (
        <Fragment>
            <div className="contact-us-container">
                <h1>Contact Us</h1>
                <p>If you have any questions or comments, please don't hesitate to reach out to us. We're here to help!</p>

                <h2>Contact Information</h2>
                <p><strong>Phone Numbers:</strong></p>
                <ul>
                    <li>+91 7676888339</li>
                    <li>+91 9743777062</li>
                </ul>

                <p><strong>Email:</strong></p>
                <p><a href="mailto:gnrshopping2@gmail.com">gnrshopping2@gmail.com</a></p>

                <p><strong>Address:</strong></p>
                <p><a href="https://www.google.com/maps/place/Sree+venkateshwara+cyber+,+Xerox+and+Digital+textile+printing/@13.3408834,77.9884804,19z/data=!4m15!1m8!3m7!1s0x3bb203d7b9782cbf:0xd6085ab79b32c15e!2sKaiwara,+Karnataka!3b1!8m2!3d13.3516095!4d77.9938497!16zL20vMDlqdmxk!3m5!1s0x3bb201cf98a656bf:0x852dbf6eadae7386!8m2!3d13.3409453!4d77.9886086!16s%2Fg%2F11szc0wxrp?entry=ttu">And, Main Road, Post, In Front of Govt Junior College, Kaiwara, Karnataka 563128</a></p>
                <h2>Business Hours</h2>
                <p>Monday - Friday: 9:00 AM - 6:00 PM</p>
                <p>Saturday: 10:00 AM - 4:00 PM</p>
                <p>Sunday: Closed</p>

                <h2>Follow Us</h2>
                <p>Stay connected with us on social media:</p>
                <ul>
                    <li><a href="https://www.facebook.com/profile.php?id=61561563291622" >Facebook</a></li>
                    <li><a href="https://www.instagram.com/gnr_shopping__/" >Instagram</a></li>
                </ul>
            </div>
        </Fragment>
    );
};

export default ContactUs;
