import React from 'react';
import './TermsAndConditions.css';

const TermsAndConditions = () => {
  return (
    <div className="terms-and-conditions-container">
      <h1>Terms and Conditions</h1>
      
      <h2>Introduction</h2>
      <p>Welcome to GNR Shopping. These terms and conditions outline the rules and regulations for the use of our website.</p>
      
      <h2>Intellectual Property Rights</h2>
      <p>Other than the content you own, under these Terms, GNR Shopping and/or its licensors own all the intellectual property rights and materials contained in this website. You are granted a limited license only for purposes of viewing the material contained on this website.</p>
      
      <h2>Restrictions</h2>
      <p>You are specifically restricted from all of the following:</p>
      <ul>
        <li>Publishing any website material in any other media;</li>
        <li>Selling, sublicensing, and/or otherwise commercializing any website material;</li>
        <li>Publicly performing and/or showing any website material;</li>
        <li>Using this website in any way that is or may be damaging to this website;</li>
        <li>Using this website in any way that impacts user access to this website;</li>
        <li>Using this website contrary to applicable laws and regulations;</li>
        <li>Engaging in any data mining, data harvesting, data extracting, or any other similar activity in relation to this website;</li>
        <li>Using this website to engage in any advertising or marketing.</li>
      </ul>
      <p>Certain areas of this website are restricted from being accessed by you, and GNR Shopping may further restrict access by you to any areas of this website at any time, in absolute discretion. Any user ID and password you may have for this website are confidential, and you must maintain confidentiality as well.</p>
      
      <h2>Your Content</h2>
      <p>In these website Standard Terms and Conditions, "Your Content" shall mean any audio, video, text, images, or other material you choose to display on this website. By displaying Your Content, you grant GNR Shopping a non-exclusive, worldwide, irrevocable, sublicensable license to use, reproduce, adapt, publish, translate, and distribute it in any and all media.</p>
      <p>Your Content must be your own and must not be invading any third-party’s rights. GNR Shopping reserves the right to remove any of Your Content from this website at any time without notice.</p>
      
      <h2>No warranties</h2>
      <p>This website is provided "as is," with all faults, and GNR Shopping expresses no representations or warranties, of any kind related to this website or the materials contained on this website. Also, nothing contained on this website shall be interpreted as advising you.</p>
      
      <h2>Limitation of liability</h2>
      <p>In no event shall GNR Shopping, nor any of its officers, directors, and employees, be held liable for anything arising out of or in any way connected with your use of this website whether such liability is under contract. GNR Shopping, including its officers, directors, and employees, shall not be held liable for any indirect, consequential, or special liability arising out of or in any way related to your use of this website.</p>
      
      <h2>Indemnification</h2>
      <p>You hereby indemnify to the fullest extent GNR Shopping from and against any and/or all liabilities, costs, demands, causes of action, damages, and expenses arising in any way related to your breach of any of the provisions of these Terms.</p>
      
      <h2>Severability</h2>
      <p>If any provision of these Terms is found to be invalid under any applicable law, such provisions shall be deleted without affecting the remaining provisions herein.</p>
      
      <h2>Variation of Terms</h2>
      <p>GNR Shopping is permitted to revise these Terms at any time as it sees fit, and by using this website, you are expected to review these Terms on a regular basis.</p>
      
      <h2>Assignment</h2>
      <p>The GNR Shopping is allowed to assign, transfer, and subcontract its rights and/or obligations under these Terms without any notification. However, you are not allowed to assign, transfer, or subcontract any of your rights and/or obligations under these Terms.</p>
      
      <h2>Entire Agreement</h2>
      <p>These Terms constitute the entire agreement between GNR Shopping and you in relation to your use of this website and supersede all prior agreements and understandings.</p>
      
      <h2>Governing Law & Jurisdiction</h2>
      <p>These Terms will be governed by and interpreted in accordance with the laws of the State of Karnataka, and you submit to the non-exclusive jurisdiction of the state and federal courts located in Karnataka for the resolution of any disputes.</p>
    </div>
  );
};

export default TermsAndConditions;
