import React, { Fragment } from "react";
import "./Cart.css";
import CartItemCard from "./CartItemCard.js";
import { useSelector, useDispatch } from "react-redux";
import { addItemsToCart, removeItemsFromCart } from "../../actions/cartAction.js";
import { Typography } from "@mui/material";
import RemoveShoppingCartIcon from "@mui/icons-material/RemoveShoppingCart";
import { Link, useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import Loader from "../layout/Loader/Loader.js";

const Cart = () => {
  const dispatch = useDispatch();
  const { cartItems,loading} = useSelector((state) => state.cart);
  const alert = useAlert();
  const navigate = useNavigate();

  const increaseQuantity = (id, quantity, stock) => {
    const newQty = quantity + 1;
    if (stock <= quantity) {
      return;
    }
    dispatch(addItemsToCart(id, newQty));
  };

  const decreaseQuantity = (id, quantity) => {
    const newQty = quantity - 1;
    if (newQty < 1) {
      return;
    }
    dispatch(addItemsToCart(id, newQty));
  };

  const deleteCartItems = (id) => {
    dispatch(removeItemsFromCart(id));
  };

  const checkoutHandler = async () => {
    let hasOutOfStockItem = false;

    cartItems.forEach((item) => {

      increaseQuantity(item.product, item.quantity, item.stock);
      decreaseQuantity(item.product, item.quantity+1);
      if (item.stock === 0) {
        hasOutOfStockItem = true;
      }

    });

    if (hasOutOfStockItem) {
      alert.error("Some items are out of stock. Cannot proceed to checkout.");
    } else {
      navigate("/login?redirect=shipping");
    }
  };

  return (
  <Fragment>
    {loading ? (
      <Loader />
    ) : 
    <Fragment>
      {cartItems.length === 0 ? (
        <div className="emptyCart">
          <RemoveShoppingCartIcon />
          <Typography>No Product in Your Cart</Typography>
          <Link to="/products">View Products</Link>
        </div>
      ) : (
        <Fragment>
          <div className="cartPage">
            <div className="cartHeader">
              <p>Product</p>
              <p>Quantity</p>
              <p>Subtotal</p>
            </div>

            {cartItems.map((item) => (
              <div className="cartContainer" key={item.product}>
                <CartItemCard item={item} deleteCartItems={deleteCartItems} />
                <div className="cartInput">
                  <button onClick={() => decreaseQuantity(item.product, item.quantity)}>
                    <RemoveRoundedIcon/>
                  </button>
                  <input type="number" value={item.quantity} readOnly />
                  <button onClick={() => increaseQuantity(item.product, item.quantity, item.stock)}>
                    <AddRoundedIcon/>
                  </button>
                </div>
                <p className="cartSubtotal">{`₹${item.price * item.quantity}`}</p>
              </div>
            ))}

            <div className="cartGrossProfit">
              <div></div>
              <div className="cartGrossProfitBox">
                <p>Gross Total</p>
                <p>{`₹${cartItems.reduce((acc, item) => acc + item.quantity * item.price, 0)}`}</p>
              </div>
              <div></div>
              <div className="checkOutBtn">
                <button onClick={checkoutHandler}>
                  Check Out
                </button>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
      }
    </Fragment>
  );
};

export default Cart;
