import React, { Fragment, useEffect, useRef, useState } from "react";
import CheckoutSteps from "../Cart/CheckoutSteps";
import { useSelector, useDispatch } from "react-redux";
import MetaData from "../layout/MetaData";
import { useAlert } from "react-alert";
import axios from "axios";
import "./Payment.css";
import { useNavigate } from "react-router-dom";
import { createOrder, clearErrors } from "../../actions/orderAction";

const Payment = () => {
  const [paymentMethod, setPaymentMethod] = useState("paypal");

  const orderInfo = JSON.parse(sessionStorage.getItem("orderInfo"));

  let navigate = useNavigate();

  const { shippingInfo, cartItems } = useSelector((state) => state.cart);
  const { user } = useSelector((state) => state.user);
  const { error } = useSelector((state) => state.newOrder);

  const dispatch = useDispatch();
  const alert = useAlert();
  const payBtn = useRef(null);

  const paymentData = {
    amount: Math.round(orderInfo.totalPrice * 100), 
  };

  const order = {
    shippingInfo,
    orderItems: cartItems,
    itemsPrice: orderInfo.subtotal,
    taxPrice: orderInfo.tax,
    shippingPrice: orderInfo.shippingCharges,
    totalPrice: orderInfo.totalPrice,
  };

  const loadPayPalScript = () => {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = `https://www.paypal.com/sdk/js?client-id=AaS4TtRAvT4I8buIkguahOE0sPQrSOZF8rEzIc-mh0Guwlz2hFgmTrPuvO5m-o0DUNpFF29Zj4Cp-n5H`;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  };

  const displayPayPal = async () => {
    const res = await loadPayPalScript();

    if (!res) {
      alert.error("PayPal SDK failed to load. Are you online?");
      return;
    }

    try {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [{
                amount: {
                  currency_code: "USD",
                  value: (orderInfo.totalPrice).toFixed(2) 
            }}]
            });
          },
          onApprove: async (data, actions) => {
            return actions.order.capture().then(async (details) => {
              order.paymentInfo = {
                id: details.id,
                status: "succeeded",
              };
              dispatch(createOrder(order));
              navigate("/success");
            });
          },
          onError: (err) => {
            alert.error(err.message);
            payBtn.current.disabled = false;
          }
        })
        .render("#paypal-button-container");
    } catch (error) {
      payBtn.current.disabled = false;
      alert.error(error.message);
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    payBtn.current.disabled = true;
    displayPayPal();
  };

  const codHandler = () => {
    order.paymentInfo = {
      id: "COD",
      status: "Pending",
    };

    dispatch(createOrder(order));
    navigate("/success");
  };

  useEffect(() => {
    if (error) {
      alert.error(error);
      dispatch(clearErrors());
    }
  }, [dispatch, error, alert]);

  return (
    <Fragment>
      <MetaData title="Payment" />
      <CheckoutSteps activeStep={2} />
      <div className="paymentContainer">
        {/* {paymentMethod === "paypal" ? (
          <div>
            <div id="paypal-button-container"></div>
            <form className="paymentForm" onSubmit={(e) => submitHandler(e)}>
              <input
                type="submit"
                value={`Pay - ₹${orderInfo && orderInfo.totalPrice}.00`}
                ref={payBtn}
                className="paymentFormBtn"
              />
            </form>
          </div>
        ) : ( */}
          <div className="codContainer">
            <button onClick={codHandler} className="codButton">
              Confirm Cash on Delivery
            </button>
          </div>
        {/* )} */}
        {/* <div className="state">----------OR----------</div>
        <div className="othersContainer"> */}
          {/* <button
            className={`paymentMethodButton ${
              paymentMethod === "paypal" ? "active" : ""
            }`}
            onClick={() => setPaymentMethod("paypal")}
          >
            Pay with PayPal
          </button> */}
          {/* <button
            className={`paymentMethodButton ${
              paymentMethod === "COD" ? "active" : ""
            }`}
            onClick={() => setPaymentMethod("COD")}
          >
            Cash on Delivery
          </button> */}
        {/* </div> */}
      </div>
    </Fragment>
  );
};

export default Payment;
