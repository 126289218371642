import React, { Fragment, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import GroupsIcon from "@mui/icons-material/Groups";
import FeedbackIcon from "@mui/icons-material/Feedback";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import MenuRoundedIcon from '@mui/icons-material/MenuRounded';
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useSelector } from "react-redux";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AddIcon from "@mui/icons-material/Add";
import ListAltIcon from "@mui/icons-material/ListAlt";
import DashboardIcon from "@mui/icons-material/Dashboard";
import PeopleIcon from "@mui/icons-material/People";
import RateReviewIcon from "@mui/icons-material/RateReview";
import HomeIcon from "@mui/icons-material/Home";
import logo from "../../images/logo.jpg";
import CurrencyExchangeIcon from '@mui/icons-material/CurrencyExchange';
import ReplayIcon from '@mui/icons-material/Replay';
import "./Sidebar.css";
import Badge from "@mui/material/Badge";

const Header = () => {
  const [keyword, setKeyword] = useState("");

  const { isAuthenticated } = useSelector((state) => state.user);
  const cartItems = useSelector((state) => state.cart.cartItems);
  const [isFormSticky, setIsFormSticky] = useState(false);

  const navigate = useNavigate();

  const searchSubmitHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      navigate(`/products/${keyword}`);
    } else {
      navigate("/products");
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsFormSticky(scrollTop > 110);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Fragment>
      {[false].map((expand) => (
        <Navbar
          key={expand}
          expand={expand}
          className="bg-body-tertiary mb-3"
          style={{ paddingTop: "0" }}
        >
          <Container
            style={{
              marginTop: "0",
              marginBottom: "0",
              zIndex: 1000,
              top: 0,
              left: 0,
              width: "100%",
              background: "#1c9da3",
              paddingLeft: "0",
              paddingRight: "0",
              height: "50px",
            }}
          >
            <img src={logo} alt="logo" style={{ width: "80px" }}></img>
            <Form
              className="form"
              inline="true"
              style={{ marginLeft: "auto", marginRight: "auto", width: "40vw" }}
              onSubmit={searchSubmitHandler}
            >
              <Row className="justify-content-center">
                <Col style={{ paddingLeft: "50px", paddingRight: "0" }}>
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    onChange={(e) => setKeyword(e.target.value)}
                    className="mr-sm-2 w-100"
                  />
                </Col>
                <Col
                  xs="auto"
                  style={{
                    paddingLeft: "4px",
                    paddingRight: "0",
                    height: "50%",
                  }}
                >
                  <Button
                    style={{ borderRadius: "50%", backgroundColor: "#a31c60" }}
                    type="submit"
                    value="search"
                  >
                    <SearchRoundedIcon fontSize='medium'/>
                  </Button>
                </Col>
              </Row>
            </Form>
            {!isAuthenticated ? (
              <Button
                href="/login"
                id="loginButton"
                style={{
                  fontWeight: 500,
                  color: "white",
                  backgroundColor: "#a31c60",
                  height: "44px",
                  paddingLeft: "6px",
                  paddingRight: "6px",
                  marginLeft: "auto",
                }}
              >
                <AccountCircleIcon fontSize="large" /> Login
              </Button>
            ) : (
              <Button
                href="/login"
                id="loginButton"
                style={{
                  fontWeight: 500,
                  color: "white",
                  backgroundColor: "#a31c60",
                  height: "44px",
                  paddingLeft: "6px",
                  paddingRight: "6px",
                  marginLeft: "auto",
                }}
              >
                <AccountCircleIcon fontSize="large" /> Profile
              </Button>
            )}
            <Button
              href="/cart"
              style={{
                borderRadius: "50%",
                height: "45px",
                backgroundColor: "#a31c60",
                marginLeft: "10px",
                marginRight: "10px",
              }}
              value="search"
            >
              <Badge badgeContent={cartItems.length} color="none">
                <ShoppingCartIcon />
              </Badge>
            </Button>
            <Navbar.Toggle
              style={{ position: "fixed", padding: "0" }}
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              children={
                <MenuRoundedIcon fontSize='large' />
              }
            />
            <Navbar.Offcanvas
              style={{ width: "35vw" }}
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
            >
              <Offcanvas.Header
                style={{
                  background: "#1c9da3",
                  paddingTop: "12px",
                  paddingBottom: "12px",
                }}
                closeButton
              >
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  Admin Menu
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body>
                <Nav className="justify-content-end flex-grow-1 pe-3">
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      margin: "10px",
                    }}
                    to="/"
                  >
                    <HomeIcon /> Home
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      margin: "10px",
                    }}
                    to="/admin/dashboard"
                  >
                    <DashboardIcon /> Dashboard
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      margin: "10px",
                    }}
                    to="/admin/products"
                  >
                    <PostAddIcon /> All Products
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      margin: "10px",
                    }}
                    to="/admin/product"
                  >
                    <AddIcon /> Create Products
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      margin: "10px",
                    }}
                    to="/admin/orders"
                  >
                    {" "}
                    <ListAltIcon /> Orders
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      margin: "10px",
                    }}
                    to="/admin/users"
                  >
                    <PeopleIcon /> Users
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      margin: "10px",
                    }}
                    to="/admin/reviews"
                  >
                    <RateReviewIcon /> Reviews
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      margin: "10px",
                    }}
                    to="/about"
                  >
                    <GroupsIcon /> About Us
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      margin: "10px",
                    }}
                    to="/contactus"
                  >
                    <FeedbackIcon /> Contact Us
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      margin: "10px",
                    }}
                    to="/refund"
                  >
                    <CurrencyExchangeIcon /> Refund
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      margin: "10px",
                    }}
                    to="/return"
                  >
                    <ReplayIcon /> Return
                  </Link>
                  <Link
                    style={{
                      textDecoration: "none",
                      color: "black",
                      margin: "10px",
                    }}
                    to="/termsandcondition"
                  >
                   Terms And Conditions
                  </Link>
                </Nav>
              </Offcanvas.Body>
            </Navbar.Offcanvas>
          </Container>
          <Form
            className={`formphone ${isFormSticky ? "sticky" : ""}`}
            inline="true"
            onSubmit={searchSubmitHandler}
          >
            <Row className="justify-content-center">
              <Col
                style={{
                  paddingLeft: "20px",
                  paddingRight: "0",
                  paddingBottom: "10px",
                  paddingTop: "10px",
                }}
              >
                <Form.Control
                  type="text"
                  placeholder="Search"
                  onChange={(e) => setKeyword(e.target.value)}
                  className="mr-sm-2 w-100"
                  style={{ width: "60vw" }}
                />
              </Col>
              <Col
                xs="auto"
                style={{
                  paddingLeft: "4px",
                  marginRight: "10px",
                  height: "50%",
                  paddingTop: "10px",
                }}
              >
                <Button
                  style={{ borderRadius: "50%", backgroundColor: "#a31c60" }}
                  type="submit"
                  value="search"
                >
                  <SearchRoundedIcon fontSize='medium'/>
                </Button>
              </Col>
            </Row>
          </Form>
        </Navbar>
      ))}
    </Fragment>
  );
};

export default Header;
