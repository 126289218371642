import React, { Fragment, useEffect, useState } from "react";
import "./Products.css";
import NavigateNextRoundedIcon from '@mui/icons-material/NavigateNextRounded';
import NavigateBeforeRoundedIcon from '@mui/icons-material/NavigateBeforeRounded';
import { useSelector, useDispatch } from "react-redux";
import { clearErrors, getProduct } from "../../actions/productAction";
import Loader from "../layout/Loader/Loader";
import ProductCard from "../Home/ProductCard";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import { useAlert } from "react-alert";
import MetaData from "../layout/MetaData";
import { useParams } from "react-router-dom";
import Pagination from "react-js-pagination";

const categories = [
    "Stationary",
    "Sports",
    "Art & Craft",
    "Gift Articles",
    "Mobile Accessories",
    "Computer Accesories",
    "Electronics",
    "Toys",
    "Beauty Products",
];

const Products = () => {
    const dispatch = useDispatch();
    const alert = useAlert();
    const { keyword } = useParams();

    const [currentPage, setCurrentPage] = useState(1);
    const [price, setPrice] = useState([0, 25000]);
    const [minPriceInput, setMinPriceInput] = useState(0);
    const [maxPriceInput, setMaxPriceInput] = useState(25000);
    const [category, setCategory] = useState("");
    const [ratings, setRatings] = useState(0);

    const {
        products,
        loading,
        error,
        productsCount,
        resultPerPage,
        filterProductsCount,
    } = useSelector((state) => state.products);

    const setCurrentPageNo = (e) => {
        setCurrentPage(e);
    };

    const handleMinPriceChange = (event) => {
        let value = event.target.value;
        if (value === "") {
            setMinPriceInput("");
        } else {
            let intValue = parseInt(value);
            if (!isNaN(intValue)) {
                setMinPriceInput(intValue);
            }
        }
    };

    const handleMaxPriceChange = (event) => {
        let value = event.target.value;
        if (value === "") {
            setMaxPriceInput("");
        } else {
            let intValue = parseInt(value);
            if (!isNaN(intValue)) {
                setMaxPriceInput(intValue);
            }
        }
    };

    const applyPriceRange = () => {
        if (minPriceInput <= maxPriceInput) {
            setPrice([minPriceInput || 0, maxPriceInput || 25000]);
        } else {
            alert.error("Minimum price cannot be greater than maximum price");
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === "Enter") {
            applyPriceRange();
        }
    };

    const handleBlur = () => {
        applyPriceRange();
    };

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }

        dispatch(getProduct(keyword, currentPage, price, category, ratings));
    }, [dispatch, keyword, currentPage, price, category, ratings, alert, error]);

    let count = filterProductsCount;

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <MetaData title="PRODUCTS -- GNR Shopping" />
                    <div className="container">
                        <h2 className="productsHeading">Products</h2>
                        <div className="priceBox">
                            <Typography style={{ fontWeight: 1000, fontSize: "3vmin" }}>Price</Typography>
                            <div className="priceInput">
                                <input
                                    type="number"
                                    value={minPriceInput}
                                    onChange={handleMinPriceChange}
                                    onBlur={handleBlur}
                                    onKeyPress={handleKeyPress}
                                />
                                <span>~</span>
                                <input
                                    type="number"
                                    value={maxPriceInput}
                                    onChange={handleMaxPriceChange}
                                    onBlur={handleBlur}
                                    onKeyPress={handleKeyPress}
                                />
                            </div>
                            <Typography variant="caption" style={{ display: "flex", padding: 0, fontWeight: 1000}}>
                                Min: ₹ {price[0]} - Max: ₹ {price[1]}
                            </Typography>
                        </div>
                        <div className="products">
                            {products &&
                                products.map((product) => (
                                    <ProductCard key={product._id} product={product} />
                                ))}
                        </div>
                        <div className="filterBox">
                            <Typography style={{ fontWeight: 1000 }}>Categories</Typography>
                            <ul className="categoryBox">
                                {categories.map((category) => (
                                    <li
                                        className="category-link"
                                        key={category}
                                        onClick={() => setCategory(category)}
                                    >
                                        {category}
                                    </li>
                                ))}
                            </ul>
                            <fieldset>
                                <Typography style={{ fontWeight: 1000 }} component="legend">Ratings</Typography>
                                <Slider
                                    value={ratings}
                                    onChange={(e, newRating) => {
                                        setRatings(newRating);
                                    }}
                                    aria-labelledby="continuous-slider"
                                    valueLabelDisplay="on"
                                    min={0}
                                    max={5}
                                />
                            </fieldset>
                        </div>
                    </div>
                    {resultPerPage < count && (
                        <div className="paginationBox">
                            <Pagination
                                activePage={currentPage}
                                itemsCountPerPage={resultPerPage}
                                totalItemsCount={productsCount}
                                onChange={setCurrentPageNo}
                                nextPageText={<NavigateNextRoundedIcon/>}
                                prevPageText={<NavigateBeforeRoundedIcon/>}
                                firstPageText="1st"
                                lastPageText="Last"
                                itemClass="page-item"
                                linkClass="page-link"
                                activeClass="pageItemActive"
                                activeLinkClass="pageLinkActive"
                            />
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

export default Products;
