import React, { Fragment, useEffect, useState } from "react";
import MetaData from "../layout/MetaData";
import { Link, useParams } from "react-router-dom";
import { Typography } from "@mui/material";
import SideBar from "./Sidebar";
import {
    getOrderDetails,
    clearErrors,
    updateOrder,
} from "../../actions/orderAction";
import { useSelector, useDispatch } from "react-redux";
import Loader from "../layout/Loader/Loader";
import { useAlert } from "react-alert";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Button from '@mui/material/Button';
import { UPDATE_ORDER_RESET } from "../../constants/orderConstants";
import "./ProcessOrder.css";

const ProcessOrder = () => {
    const { order, error, loading } = useSelector((state) => state.orderDetails);
    const { error: updateError, isUpdated } = useSelector((state) => state.order);
    const { id } = useParams();

    const [status, setStatus] = useState("");
    const [paymentStatus, setPaymentStatus] = useState("");

    const dispatch = useDispatch();
    const alert = useAlert();

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        if (updateError) {
            alert.error(updateError);
            dispatch(clearErrors());
        }
        if (isUpdated) {
            alert.success("Order Updated Successfully");
            dispatch({ type: UPDATE_ORDER_RESET });
        }

        dispatch(getOrderDetails(id));
    }, [dispatch, alert, error, id, isUpdated, updateError]);

    const updateOrderSubmitHandler = (e) => {
        e.preventDefault();

        const myForm = new FormData();

        if (paymentStatus) {
            myForm.set("paymentStatus", paymentStatus);
        }
        if(status){
            myForm.set("status", status);
        }

        dispatch(updateOrder(id, myForm));
    };

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <MetaData title="Process Order" />
                    <div className="dashboard">
                        <SideBar />
                        <div className="newProductContainer" style={{ overflowY: "auto" }}>
                            <div
                                className="confirmOrderPage"
                                style={{
                                    display: order.orderStatus === "Delivered" ? "block" : "grid"
                                }}
                            >
                                <div>
                                    <div className="confirmshippingArea">
                                        <Typography>Shipping Info</Typography>
                                        <div className="orderDetailsContainerBox">
                                            <div>
                                                <p>Name:</p>
                                                <span>{order.user && order.user.name}</span>
                                            </div>
                                            <div>
                                                <p>Phone:</p>
                                                <span>
                                                    {order.shippingInfo && order.shippingInfo.phoneNo}
                                                </span>
                                            </div>
                                            <div>
                                                <p>Address:</p>
                                                <span>
                                                    {order.shippingInfo &&
                                                        `${order.shippingInfo.address}, ${order.shippingInfo.city}, ${order.shippingInfo.state}, ${order.shippingInfo.pinCode}, ${order.shippingInfo.country}`}
                                                </span>
                                            </div>
                                        </div>

                                        <Typography>Payment</Typography>
                                        <div className="orderDetailsContainerBox">
                                            <div>
                                                <p
                                                    className={
                                                        order.paymentInfo &&
                                                        order.paymentInfo.status === "succeeded"
                                                            ? "greenColor"
                                                            : "redColor"
                                                    }
                                                >
                                                    {order.paymentInfo &&
                                                    order.paymentInfo.status === "succeeded"
                                                        ? "PAID"
                                                        : "NOT PAID"}
                                                </p>
                                            </div>

                                            <div>
                                                <p>Amount:</p>
                                                <span>₹{order.totalPrice && order.totalPrice}</span>
                                            </div>
                                        </div>

                                        <Typography>Order Status</Typography>
                                        <div className="orderDetailsContainerBox">
                                            <div>
                                                <p
                                                    className={
                                                        order.orderStatus && order.orderStatus === "Delivered"
                                                            ? "greenColor"
                                                            : "redColor"
                                                    }
                                                >
                                                    {order.orderStatus && order.orderStatus}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="confirmCartItems">
                                        <Typography>Your Cart Items:</Typography>
                                        <div className="confirmCartItemsContainer">
                                            {order.orderItems &&
                                                order.orderItems.map((item) => (
                                                    <div key={item.product}>
                                                        <img src={item.image} alt="Product" />
                                                        <Link to={`/product/${item.product}`}>
                                                            {item.name}
                                                        </Link>{" "}
                                                        <span>
                                                            {item.quantity} X ₹{item.price} ={" "}
                                                            <b>₹{item.price * item.quantity}</b>
                                                        </span>
                                                    </div>
                                                ))}
                                        </div>
                                    </div>
                                </div>
                                <div
                                    style={{
                                        display: order.orderStatus === "Delivered" ? "none" : "block",
                                    }}
                                >
                                    <form
                                        className="updateOrderForm"
                                        onSubmit={updateOrderSubmitHandler}
                                    >
                                        <h1>Process Order</h1>

                                        <div>
                                            <AccountTreeIcon />
                                            <select onChange={(e) => setStatus(e.target.value)}>
                                                <option value="">Choose Shipment</option>
                                                {order.orderStatus === "Processing" && (
                                                    <option value="Shipped">Shipped</option>
                                                )}

                                                {order.orderStatus === "Shipped" && (
                                                    <option value="Delivered">Delivered</option>
                                                )}
                                            </select>
                                        </div>
                                        <div>
                                            <AccountTreeIcon />
                                            <select onChange={(e) => setPaymentStatus(e.target.value)}>
                                                <option value="">Choose Payment</option>
                                                <option value="succeeded">Paid</option>
                                            </select>
                                        </div>

                                        <Button
                                            id="createProductBtn"
                                            type="submit"
                                            disabled={loading || (status === "" && paymentStatus === "")}
                                        >
                                            Process
                                        </Button>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default ProcessOrder;
