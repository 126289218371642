import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import axios from 'axios';
import Footer from "./component/layout/Footer/Footer.js";
import Header from "./component/layout/Header/Header.js";
import Home from "./component/Home/Home.js";
import ProductDetails from "./component/Product/ProductDetails.js";
import Products from "./component/Product/Products.js";
import LoginSignUp from "./component/User/LoginSignUp.js";
import store from "./store";
import { loadUser } from "./actions/userAction.js";
import UserOptions from "./component/layout/Header/UserOptions.js";
import { useSelector } from "react-redux";
import Profile from "./component/User/Profile.js";
import ProtectedRoute from "./component/Route/ProtectedRoute.js";
import UpdateProfile from "./component/User/UpdateProfile.js";
import UpdatePassword from "./component/User/UpdatePassword.js";
import ForgotPassword from "./component/User/ForgotPassword.js";
import ResetPassword from "./component/User/ResetPassword.js";
import Cart from "./component/Cart/Cart.js";
import Shipping from "./component/Cart/Shipping.js";
import ScrollToTop from './component/Product/ScrollToTop.js';
import ConfirmOrder from "./component/Cart/ConfirmOrder.js";
import Payment from "./component/Cart/Payment.js";
import OrderSuccess from "./component/Cart/OrderSuccess.js";
import MyOrders from "./component/Order/MyOrders.js";
import OrderDetails from "./component/Order/OrderDetails.js";
import Dashboard from "./component/Admin/Dashboard.js";
import ProductList from "./component/Admin/ProductList.js";
import NewProduct from "./component/Admin/NewProduct.js";
import UpdateProduct from "./component/Admin/UpdateProduct.js";
import OrderList from "./component/Admin/OrderList.js";
import ProcessOrder from "./component/Admin/ProcessOrder.js";
import UsersList from "./component/Admin/UsersList.js";
import UpdateUser from "./component/Admin/UpdateUser.js";
import ProductReviews from "./component/Admin/ProductReviews.js";
import About from "./component/Admin/About.js";
import ContactUs from "./component/Admin/ContactUs.js";
import RefundPolicy from "./component/Admin/Refund.js";
import ReturnPolicy from "./component/Admin/Return.js";
import TermsAndCondition from "./component/Admin/TermsAndCondition.js";

function App() {
  const { isAuthenticated, user } = useSelector((state) => state.user);
  const [paypalClientId, setPaypalClientId] = useState("");
  const [paypalClientIdFetched, setPaypalClientIdFetched] = useState(false);

  useEffect(() => {
    store.dispatch(loadUser());
    if (!paypalClientIdFetched) {
      getPaypalClientId();
    }
  }, [paypalClientIdFetched]);

  async function getPaypalClientId() {
    try {
      const { data } = await axios.get("/api/v1/paypalapikey");
      setPaypalClientId(data.paypalClientId);
      setPaypalClientIdFetched(true);
    } catch (error) {
      console.error("Error fetching PayPal Client ID:", error);
    }
  }

  return (
    <Router>
      <Header />
      <ScrollToTop />
      {isAuthenticated && <UserOptions user={user} />}
      <PayPalScriptProvider options={{ "client-id": paypalClientId }}>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route exact path="/product/:id" element={<ProductDetails />} />
          <Route exact path="/products" element={<Products />} />
          <Route path="/products/:keyword" element={<Products />} />
          <Route element={<ProtectedRoute />}>
            <Route exact path='/account' element={<Profile />} />
            <Route exact path='/me/update' element={<UpdateProfile />} />
            <Route exact path='/password/update' element={<UpdatePassword />} />
          </Route>
          <Route exact path="/password/forgot" element={<ForgotPassword />} />
          <Route exact path="/password/reset/:token" element={<ResetPassword />} />
          <Route exact path="/login" element={<LoginSignUp />} />
          <Route exact path="/cart" element={<Cart />} />
          <Route element={<ProtectedRoute />}>
            <Route exact path='/shipping' element={<Shipping />} />
            <Route exact path='/order/confirm' element={<ConfirmOrder />} />
            <Route exact path='/process/payment' element={<Payment />} />
            <Route exact path='/success' element={<OrderSuccess />} />
            <Route exact path='/orders' element={<MyOrders />} />
            <Route exact path='/order/:id' element={<OrderDetails />} />
            <Route isAdmin={true} exact path='/admin/dashboard' element={<Dashboard />} />
            <Route isAdmin={true} exact path="/admin/products" element={<ProductList />} />
            <Route isAdmin={true} exact path="/admin/product" element={<NewProduct />} />
            <Route isAdmin={true} exact path="/admin/product/:id" element={<UpdateProduct />} />
            <Route isAdmin={true} exact path="/admin/orders" element={<OrderList />} />
            <Route isAdmin={true} exact path="/admin/order/:id" element={<ProcessOrder />} />
            <Route isAdmin={true} exact path="/admin/users" element={<UsersList />} />
            <Route isAdmin={true} exact path="/admin/user/:id" element={<UpdateUser />} />
            <Route isAdmin={true} exact path="/admin/reviews" element={<ProductReviews />} />
          </Route>
          <Route exact path='/about' element={<About />} />
          <Route exact path='/contactus' element={<ContactUs />} />
          <Route exact path='/refund' element={<RefundPolicy />} />
          <Route exact path='/return' element={<ReturnPolicy />} />
          <Route exact path='/termsandcondition' element={<TermsAndCondition />} />
        </Routes>
      </PayPalScriptProvider>
      <Footer />
    </Router>
  );
}

export default App;
