import React from "react";
import "./Footer.css";

const Footer = () => {
    return (
        <footer id="footer">
            <div className="leftFooter">
            <h4>Contact Us</h4>
            <a href="mailto:gnrshopping2@gmail.com">Email :- gnrshopping2@gmail.com</a>
            <h4>Address</h4>
            <a href="https://www.google.com/maps/place/Sree+venkateshwara+cyber+,+Xerox+and+Digital+textile+printing/@13.3408834,77.9884804,19z/data=!4m15!1m8!3m7!1s0x3bb203d7b9782cbf:0xd6085ab79b32c15e!2sKaiwara,+Karnataka!3b1!8m2!3d13.3516095!4d77.9938497!16zL20vMDlqdmxk!3m5!1s0x3bb201cf98a656bf:0x852dbf6eadae7386!8m2!3d13.3409453!4d77.9886086!16s%2Fg%2F11szc0wxrp?entry=ttu">and, Main road, post, in front of govt junior college, Kaiwara, Karnataka 563128</a>
            </div>
            <div className="midFooter">
                <h1>GNR Shopping</h1>

                <p>Copyrights 2024 &copy; GNR Shopping</p>
            </div>
            <div className="rightFooter">
                <h4>Follow Us</h4>
                <a href="https://www.instagram.com/gnr_shopping__/">Instagram</a>
                <a href="https://www.facebook.com/profile.php?id=61561563291622">Facebook</a>

            </div>
        </footer>
    )
}

export default Footer