import React from "react";
import { Link } from "react-router-dom";
import Rating from '@mui/material/Rating';


const ProductCard = ({ product }) => {
    const options = {
        size: "small",
        value: product.ratings,
        readOnly: true,
        precision: 0.5,
    };


    const percentage = Math.round(((product.price - product.discount)/product.discount) * 100);
    return (

        <Link className="productCard" to={`/product/${product._id}`}>
            <img src={product.images[0].url} alt={product.name} />
            <p>{product.name}</p>
            <div>
                <Rating {...options} />
                <span>
                    {" "}
                    ({product.numOfReviews} Reviews)
                </span>
            </div>
            <span className="discount">{`MRP: ₹ ${product.discount}`}</span>
            <span>{`${percentage}% Off`}</span>
            <span>{`₹ ${product.price}`}</span>

        </Link>

    );
};

export default ProductCard;