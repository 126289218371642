import React from 'react';
import './RefundPolicy.css';

const RefundPolicy = () => {
  return (
    <div className="refund-policy-container">
      <h1>Refund Policy</h1>
      
      <h2>Refunds (if applicable)</h2>
      <p>Once your return is received and inspected, we will send you an email to notify you that we have received your returned item. We will also notify you of the approval or rejection of your refund.</p>
      <p>If you are approved, then your refund will be processed, and a credit will automatically be applied to your credit card or original method of payment, within a certain amount of days.</p>
      
      <h2>Late or Missing Refunds</h2>
      <p>If you haven’t received a refund yet, first check your bank account again. Then contact your credit card company, it may take some time before your refund is officially posted.</p>
      <p>Next, contact your bank. There is often some processing time before a refund is posted. If you’ve done all of this and you still have not received your refund yet, please contact us at <a href="mailto:gnrshopping2@gmail.com">gnrshopping2@gmail.com</a>.</p>
      
      <h2>Partial Refunds</h2>
      <p>There are certain situations where only partial refunds are granted (if applicable):</p>
      <ul>
        <li>Any item not in its original condition, is damaged or missing parts for reasons not due to our error.</li>
        <li>Any item that is returned more than 4 days after delivery.</li>
      </ul>
      
      <h2>Contact Us</h2>
      <p>If you have any questions on how to return your item to us, or to initiate a refund, please contact us:</p>
      <ul>
        <li>Phone: 7676888339</li>
        <li>Email: <a href="mailto:gnrshopping2@gmail.com">gnrshopping2@gmail.com</a></li>
        <li>Address: And, Main Road, Post, In Front of Govt Junior College, Kaiwara, Karnataka 563128</li>
      </ul>
    </div>
  );
};

export default RefundPolicy;
