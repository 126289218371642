import React, { Fragment, useEffect } from "react";
import "./Home.css";
import Product from "./ProductCard.js";
import MetaData from "../layout/MetaData";
import { clearErrors, getProduct } from "../../actions/productAction";
import { useSelector, useDispatch } from "react-redux";
import { useAlert } from "react-alert";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../layout/Loader/Loader.js";
import stationaryImg from "../../images/Stationary.jpg";
import sportsImg from "../../images/Sports.jpg";
import artandcraftImg from "../../images/Art & Craft.jpg";
import giftarticlesImg from "../../images/Gift Articles.jpg";
import mobileImg from "../../images/Mobile Accessories.jpg";
import computeImg from "../../images/Computer Accessories.jpg";
import electronicsImg from "../../images/Electronics.jpg";
import beautyImg from "../../images/Beauty.jpg";
import toysImg from "../../images/Toys.jpg";

const Home = () => {
    const alert = useAlert();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { loading, error, products } = useSelector((state) => state.products);

    const categories = [
        { name: "Stationary", image: stationaryImg },
        { name: "Sports",image: sportsImg },
        { name: "Art & Craft",image: artandcraftImg },
        { name: "Gift Articles",image: giftarticlesImg },
        { name: "Mobile Accessories",image: mobileImg },
        { name: "Computer Accessories",image: computeImg },
        { name: "Electronics",image: electronicsImg },
        { name: "Toys",image: toysImg },
        { name: "Beauty Products",image: beautyImg },
    ];

    useEffect(() => {
        if (error) {
            alert.error(error);
            dispatch(clearErrors());
        }
        dispatch(getProduct());
    }, [dispatch, error, alert, navigate]);

    window.addEventListener('contextmenu', (e) => e.preventDefault());

    return (
        <Fragment>
            {loading ? (
                <Loader />
            ) : (
                <Fragment>
                    <MetaData title="GNR Shopping" />
                    <div className="categories-container">
                        <div className="categories">
                            {categories.map((category, index) => (
                                <Link to={`/products`} key={index} className="category-link">
                                <div className="category">
                                    <img src={category.image} alt={category.name} className="category-image" />
                                    <div className="category-name">{category.name}</div>
                                </div>
                            </Link>
                            ))}
                        </div>
                    </div>
                    <h2 className="homeHeading" style={{ color: "black" }}>Featured Products</h2>
                    <div className="container" id="container">
                        {products && products.map((product) => <Product key={product._id} product={product} />)}
                    </div>
                </Fragment>
            )}
        </Fragment>
    );
};

export default Home;